import debounce from 'lodash/debounce';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { NumberInput } from '@sravni/react-design-system';

import { TERM_SETTINGS } from '@src/constants/filters';
import { sendChangeTermFilterEvent } from '@src/helpers/analyticsEvents';
import { validateByParams } from '@src/helpers/validate';

export interface IProps {
    className?: string;
    value?: number | null;
    name: string;
    label: string;
    placeholder?: string;
    min?: number;
    max?: number;
    onChange: (value: number | undefined, name: string) => void;
    error?: string;
    format?: string | ((value: string) => string);
    postfix?: string;
    offerId?: string;
    analyticsEvent?: VoidFunction;
}

const THOUSAND_SEPARATOR = ' ';

export const TermControl: FC<IProps> = ({
    className,
    value,
    name,
    label,
    max = TERM_SETTINGS.max,
    min = TERM_SETTINGS.min,
    placeholder,
    error,
    onChange,
    postfix,
    offerId,
    analyticsEvent,

    ...props
}) => {
    const format = (value: string): string =>
        new Intl.NumberFormat().format(Math.min(Number(value.replace(/[,.]/g, '')), max ?? 365));

    const validate = useMemo(() => validateByParams({ max }), [max]);
    const validatedValue = validate(value);
    const handleChange = useRef(onChange);

    useEffect(() => {
        // валидация: если в query-параметрах кто-то вручную исправит значение
        if (validatedValue !== value) {
            handleChange.current(validatedValue, name);
        }
    }, [max, min, name, validatedValue, value]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleChangeTermInput = useCallback(
        debounce((value = 0) => {
            if (value >= min && value <= max) {
                const from = offerId ? 'Карточка детальной информации' : 'Витрина';
                analyticsEvent ? analyticsEvent() : sendChangeTermFilterEvent(value || 0, from, offerId);
                handleChange.current(value, name);
            }
        }, 1000),
        [max, min, name],
    );

    return (
        <NumberInput
            maxLength={TERM_SETTINGS.maxLength}
            label={error || label}
            className={className}
            placeholder={placeholder}
            value={validatedValue}
            min={min}
            max={max}
            // @ts-ignore
            format={format}
            invalid={Boolean(error)}
            thousandSeparator={THOUSAND_SEPARATOR}
            inputMode="numeric"
            onChange={handleChangeTermInput}
            postfix={postfix}
            {...props}
        />
    );
};
