import pick from 'lodash/pick';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useAbTestingSdk } from '@sravni/react-utils';

import { CookieKey, useCookie } from '@src/modules/cookie';
import { useUser } from '@src/reducers/user';
import { isWhiteLabelPage } from '@src/utils/routing';

import type { IMakePixelProps } from './makePixelLink';
import { makePixelLink } from './makePixelLink';

export const usePixel = (link?: string, props?: Partial<IMakePixelProps>): string | null => {
    const cookie = useCookie((cookies) =>
        pick(cookies, CookieKey._ga, CookieKey.__utmz, CookieKey._ym_uid, CookieKey.clid, CookieKey.aff_click_id),
    );
    const user = useUser();
    const { asPath, pathname, query } = useRouter();

    const isWhiteLabel = isWhiteLabelPage(asPath);

    const abTestingSdk = useAbTestingSdk();

    return useMemo(() => {
        if (!link) {
            return null;
        }

        return makePixelLink(link, {
            ...props,
            cookie,
            pathname: asPath,
            userId: user?.sub,
            isWL: isWhiteLabel,
            route: pathname,
            abTestingSdkExperiments: abTestingSdk.getAllUserExperiments(),
            query,
        });
    }, [abTestingSdk, asPath, cookie, isWhiteLabel, link, pathname, props, query, user?.sub]);
};
