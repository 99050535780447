export const BUTTON_TEXT = 'Показать предложения';

export const TERM_MIN = 5;
export const TERM_MAX = 365;
export const TERM_ERROR_MIN = 'Минимальный срок займа от 5 дней';
export const TERM_ERROR_MAX = 'Максимальный срок займа до 365 дней';
export const TERM_LABEL = 'На срок';
export const TERM_TEXT = 'от 5 до 365 дней';

export const AMOUNT_MIN_IL_VALUE = 15000;
export const AMOUNT_MIN = 3000;
export const AMOUNT_MAX = 100000;
export const AMOUNT_SLIDER_STEP = 1000;
export const AMOUNT_ERROR_MIN = 'Минимальная сумма от 3 000 рублей';
export const AMOUNT_ERROR_MAX = 'Максимальная сумма до 100 000 рублей';
export const AMOUNT_POSTFIX = '₽';
export const AMOUNT_LABEL = 'Мне нужно';
export const AMOUNT_TEXT = 'от 3 000 до 100 000 ₽';
